<template>
    
    <!-- ========================= hero-section start ========================= -->
    <section id="home">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-7 col-lg-8">
            <div class="hero-content-wrapper">
              <h2 class="mb-25 fs-3 wow fadeInDown" style="color: #3763EB" data-wow-delay=".2s">Innovative, reliable, patient-centered and accurate
              </h2>
              <h1 class="mb-25 wow fadeInDown" data-wow-delay=".2s">Tashikin Animal Health Precise to Trust</h1>
              <div style="all: initial;">
                <ul>
                  <li>● Eu CE certification: applicable to IVD products sold in the EU market.</li>
                  <li>● Us FDA registration: Applicable to IVD products sold in the US market.</li>
                  <li>● China NMPA registration: Applicable to IVD products sold in the Chinese market.</li>
                  <li>● Japan PMDA registration: Applicable to IVD products sold in the Japanese market.</li>
                  <li>● ISO certificate</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="hero-img">
              <div class="d-inline-block">
                <img src="@/assets/img/hero/hero-img.png" alt="" class="wow fadeInRight" data-wow-delay=".5s">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= hero-section end ========================= -->
    <h1 style="color:#3763EB;">Products & Capabilities</h1>
    <!-- ========================= client-logo-section start ========================= -->
    <section class="client-logo-section pt-100">
      <div class="container">
        <div class="client-logo-wrapper">
          <div class="client-logo-carousel d-flex align-items-center justify-content-between">
            <div class="card" style="width: 18rem;">
              <img src="@/assets/img/home/product.png" class="card-img-top" alt="product">
              <div class="card-body">
                <h5 class="card-title">Allergy</h5>
                <a href="#" class="btn btn-dark">Go somewhere</a>
              </div>
            </div>
            <div class="card" style="width: 18rem;">
              <img src="@/assets/img/home/product.png" class="card-img-top" alt="product">
              <div class="card-body">
                <h5 class="card-title">Allergy</h5>
                <a href="#" class="btn btn-dark">Go somewhere</a>
              </div>
            </div>
            <div class="card" style="width: 18rem;">
              <img src="@/assets/img/home/product.png" class="card-img-top" alt="product">
              <div class="card-body">
                <h5 class="card-title">Allergy</h5>
                <a href="#" class="btn btn-dark">Go somewhere</a>
              </div>
            </div>
            <div class="card" style="width: 18rem;">
              <img src="@/assets/img/home/product.png" class="card-img-top" alt="product">
              <div class="card-body">
                <h5 class="card-title">Allergy</h5>
                <a href="#" class="btn btn-dark">Go somewhere</a>
              </div>
            </div>
            <div class="card" style="width: 18rem;">
              <img src="@/assets/img/home/product.png" class="card-img-top" alt="product">
              <div class="card-body">
                <h5 class="card-title">Allergy</h5>
                <a href="#" class="btn btn-dark">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 20px">
        <button type="button" class="btn btn-primary">Contact us</button>
      </div>
      <!-- <button>←</button><button>→</button>
      <br>
      <button>contact</button> -->
    </section>
    <!-- ========================= client-logo-section end ========================= -->

    <!-- ========================= testimonial-section1 start ========================= -->
    <section class="testimonial-section pt-10 pb-10 img-bg" style="clip-path: none;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-10 mx-auto">
            <div class="col-md-12">
              <img class="img-fluid" src="@/assets/img/home/dig-into.png" alt="">
            </div>
          </div>
          <div class="col-xl-6 col-lg-10 mx-auto" style="background-color:#f7f7f7;">
            <div class="testimonial-wrapper">
              <div class="testimonial-active">
                <div class="item">
                  <div class="single-testimonial">
                    <div style="all: initial;">
                      <h2 style="color: #3763EB;padding: 20px 0 20px 0;">Product Superiority</h2>
                      <h4>●Rapid</h4>
                      <a>Test results can be obtained within 15-30 minutes.</a><br><br>
                      <h4>●High sensitivity</h4>
                      <a>Capable of detecting minute amounts of tar585px substances.</a><br><br>
                      <h4>●High sensitivity</h4>
                      <a>Strong antigen-antibody reaction specificity,ensuring accurate test results.</a><br><br>
                      <h4>●Good stability</h4>
                      <a>Exhibit excellentstability for storage and transportation.</a><br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= testimonial-section1 end ========================= -->
    <!-- ========================= testimonial-section2 start ========================= -->
    <section class="testimonial-section pt-10 pb-10 img-bg" style="clip-path: none;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-10 mx-auto" style="background-color:#f7f7f7;">
            <div class="testimonial-wrapper">
              <div class="testimonial-active">
                <div class="item">
                  <div class="single-testimonial">
                    <div style="all: initial;">
                      <h2 style="color: #3763EB;padding: 20px 0 20px 0;">Product Superiority</h2>
                      <h4>●Rapid</h4>
                      <a>Test results can be obtained within 15-30 minutes.</a><br><br>
                      <h4>●High sensitivity</h4>
                      <a>Capable of detecting minute amounts of tar585px substances.</a><br><br>
                      <h4>●High sensitivity</h4>
                      <a>Strong antigen-antibody reaction specificity,ensuring accurate test results.</a><br><br>
                      <h4>●Good stability</h4>
                      <a>Exhibit excellentstability for storage and transportation.</a><br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-10 mx-auto">
            <div class="col-md-12">
              <img class="img-fluid" src="@/assets/img/home/dig-into.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= testimonial-section2 end ========================= -->
    <!-- ========================= testimonial-section3 start ========================= -->
    <section class="testimonial-section pt-10 pb-10 img-bg" style="clip-path: none; background-color: #f7f7f7;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-3 mx-auto" style="background-color: #f7f7f7; padding: 40px;">
            <div>
              <a>Serving the pet diagnosis
                <br>and treatment industry for</a>
              <a style="font-size: 40px;font-weight: 600; color: #3763EB">12 years</a>
              <br><br>
              <a>Serving the pet diagnosis
                <br>and treatment industry for</a>
              <a style="font-size: 40px;font-weight: 600; color: #3763EB">12 years</a>
              <br><br>
              <a>Serving the pet diagnosis
                <br>and treatment industry for</a>
              <a style="font-size: 40px;font-weight: 600; color: #3763EB">12 years</a>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 mx-auto">
            <div>
              <img src="@/assets/img/home/circle-center-top.png" alt="">
            </div>
            <div>
              <img src="@/assets/img/home/circle-center-bottom.png" alt="">
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 mx-auto" style="background-color:#f7f7f7;">
            <div class="testimonial-wrapper">
              <div class="testimonial-active">
                <div class="item">
                  <div class="single-testimonial">
                    <div style="all: initial;">
                      <h2 style="color: #3763EB;padding: 20px 0 20px 0;">Clinical Data</h2>
                      <h4>●Rapid</h4>
                      <a>Test results can be obtained within 15-30 minutes.</a><br><br>
                      <h4>●High sensitivity</h4>
                      <a>Capable of detecting minute amounts of tar585px substances.</a><br><br>
                      <h4>●High sensitivity</h4>
                      <a>Strong antigen-antibody reaction specificity,ensuring accurate test results.</a><br><br>
                      <h4>●Good stability</h4>
                      <a>Exhibit excellentstability for storage and transportation.</a><br><br>
                      <button type="button" class="btn btn-primary">Contact us</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= testimonial-section3 end ========================= -->


    <!-- ========================= client-logo-section start ========================= -->
    <section class="client-logo-section pt-100">
      <div class="container">
        <h2 style="padding: 10px;color: #3763EB">Certification</h2>
        <div class="client-logo-wrapper">
          <div class="client-logo-carousel d-flex align-items-center justify-content-between">
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/certificate01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/certificate01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/certificate01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/certificate01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/certificate01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/certificate01.png" class="card-img-top" alt="certificate01">
            </div>
          </div>
        </div>
        <div class="client-logo-wrapper">
          <div class="container">
            <div class="row p-5">
              <div class="col-12 col-md-6 fs-6">
                <h4>●ISO 13485</h4>
                <a>ISO 13485 certification assures our dedication to quality, aligning our medical devices and services
                  with customer and regulatory standards.</a>
              </div>
              <div class="col-12 col-md-6 fs-6">
                <h4>●100% Perform as Promised</h4>
                <a>ISO 13485 certification assures our dedication to quality, aligning our medical devices and services
                  with customer and regulatory standards.</a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 fs-6">
                <h4>●CE</h4>
                <a>ISO 13485 certification assures our dedication to quality, aligning our medical devices and services
                  with customer and regulatory standards.</a>
              </div>
              <div class="col-12 col-md-6 fs-6">
                <h4>●100% Perform as Promised</h4>
                <a>ISO 13485 certification assures our dedication to quality, aligning our medical devices and services
                  with customer and regulatory standards.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= client-logo-section end ========================= -->
    <!-- ========================= client-logo-section start ========================= -->
    <section class="client-logo-section pt-100">
      <div class="container">
        <h2 style="padding: 10px;color: #3763EB">Cooperation Case</h2>
        <div class="client-logo-wrapper">
          <div class="client-logo-carousel d-flex align-items-center justify-content-between">
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/home-swiper01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/home-swiper01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/home-swiper01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/home-swiper01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/home-swiper01.png" class="card-img-top" alt="certificate01">
            </div>
            <div class="card" style="width: 10rem;">
              <img src="@/assets/img/home/home-swiper01.png" class="card-img-top" alt="certificate01">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= client-logo-section end ========================= -->

    <!-- ========================= client-logo-section start ========================= -->
    <section class="client-logo-section pt-100">
      <div class="container">
        <div class="client-logo-wrapper">
          <div class="container">
            <div class="row p-5">
              <div class="col-12 col-md-6 fs-6 p-3">
                <h2>Get Good Reviews of Orders</h2>
              </div>
              <div class=" row col-12 col-md-6 fs-6">
                <div class="col-3 text-center"
                  style="background-color: #3763EB;display: flex; justify-content: center; align-items: center;">
                  <img src="@/assets/img/home/home-left.svg">
                </div>
                <img class="col-9" src="@/assets/img/home/user-text.svg">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 fs-6">
                <a>With hundreds of manufacturers in our network,we have a very broad
                  range of manufacturing capabilities and support both one-off
                  prototyping and low-volume production.</a>
                <a>With hundreds of manufacturers in our network,we have a very broad
                  range of manufacturing capabilities and support both one-off
                  prototyping and low-volume production.</a>
              </div>
              <div class=" row col-12 col-md-6 fs-6">
                <img class="col-9" src="@/assets/img/home/user-text.svg">
                <div class="col-3 text-center"
                  style="background-color: #3763EB;display: flex; justify-content: center; align-items: center;">
                  <img src="@/assets/img/home/home-left.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= client-logo-section end ========================= -->

    <!-- ========================= pricing-section start ========================= -->
    <section class="pricing-section pt-200 pb-200" style="background-color: #f7f7f7;">
      <div class="container">
        <div>
          <p style="font-size: 25px; color: black;">Click the Button to Get Professional Design Advice</p>
          <br>
          <div class="pt-20 pb-20">
            <button type="button" class="btn btn-primary">Contact us</button>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= pricing-section end ========================= -->

    <!--bottom-->
    <section class="container-fluid" style="background-color: black; color: white; padding: 20px 50px 20px 50px;">
        <div class="row" style="display: flex; flex-wrap: wrap; text-align: left;">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;text-align: center;">
                <img style="width: 140px; height: 28px;" src="@/assets/img/logo/logow.svg">
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
                <h4 style="color:white;">Our Company</h4>
                <ul>
                    <li>About us</li>
                    <li>How nature's world</li>
                    <li>Become a partner</li>
                </ul><br>
                <h4 style="color:white;">Service</h4>
                <ul>
                  <li>OEM Service</li>
                  <li>About sheets & law Material</li>
                  <li>After-sales Service</li>
                </ul><br>
                <h4 style="color:white;">Resources</h4>
                <ul>
                  <li>Blog</li>
                </ul>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
                <h4 style="color:white;">Our Product</h4>
                <ul>
                  <li>Infectious Diseases</li>
                  <li>Parasites</li>
                  <li>Allergy</li>
                  <li>Myocardium</li>
                  <li>Metabolism</li>
                  <li>Kidney</li>
                  <li>Pancreatic</li>
                  <li>Inflammation</li>
                  <li>Cardiac</li>
                  <li>Bacteria</li>
                  <li>Pregnancy</li>
                </ul><br>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
              <h4 style="color:white;">Contact us</h4>
              <ul>
                <li>E-mail: info@tashikin.com</li>
                <li>Tel: +(726) 334-1765</li>
              </ul><br>
            </div>
        </div>
    </section>
 <!--bottom-->

  

</template>

