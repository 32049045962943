import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/homePage.vue";
import HowTarinnWorks from "@/views/how-it-works/how-tarinn-works/how-ordering-works.vue";
import OemService from "@/views/how-it-works/how-tarinn-works/oem-service.vue";
import DirectoryOfTests from "@/views/how-it-works/resource-support/directory-of-tests.vue";
import AfterSalesService from "@/views/how-it-works/after-sales-service/after-sales-service.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,

  },
  {
    path: "/how-it-works/how-tarinn-works/how-ordering-works",
    name: "how-tarinn-works",
    component: HowTarinnWorks,
  },
  {
    path: "/how-it-works/how-tarinn-works/oem-service",
    name: "oem-service",
    component: OemService,
  },
  {
    path: "/how-it-works/resource-support/directory-of-tests",
    name: "directory-of-tests",
    component: DirectoryOfTests,
  },
  {
    path: "/how-it-works/after-sales-service/after-sales-service",
    name: "after-sales-service",
    component: AfterSalesService,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;