<template>
    <div class="directory-of-tests">
        <div>
            <br><br>
        </div>
        <!-- ========================= imgtop start ========================= -->
        <section class="container-fluid p-0">
            <div class="row g-0">
                <div class="col-md-12 col-lg-6">
                    <div class="row bg-light">
                        <img src="@/assets/img/oem-service/home-left.png" alt="" style="width: 100%;" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6" style="background-color: #f7f7f7;">
                    <div style="text-align: left; padding: 20px 10px 10px 50px;">
                        <h1 style="color: #3763EB;">Directory of Tests</h1><br>
                        <p class="fs-5 pr-5" style="color: black;">
                            Provide pet health products: Infectious, Parasite, Allergy, Heart, Kidney, Pancreatic,
                            Inflammation, Cardiac, Bacteria, Pregnancy. Also, OEM, partner manufacturing, and raw
                            materials.
                        </p><br><br>
                        <button type="button" class="btn btn-primary">Contact us</button>
                        <p>All uploads are secure and confidential</p><br>
                        <img src="@/assets/img/home/iso.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <!-- ========================= end ========================= -->
        <h3 class="p-3" style="color: #3763EB;">Small Animal Products</h3>

        <!-- ========================= accordion-section start ========================= -->
        <section class="accordion-section">
            <div class="container">
                <div class="row mb-60">
                    <!-- accordion-style-1 -->
                    <div class="col-md-12 fs-5">
                        <div class="accordion-style">
                            <div class="accordion" id="accordionExample">
                                <div class="single-accordion mb-20 wow fadeInUp" data-wow-delay=".2s">
                                    <div class="accordion-btn">
                                        <button class="btn-block text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            <span>infectious Diseases</span>
                                        </button>
                                    </div>
                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="accordion-content">
                                            <div class="container mt-5">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>A</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">ANA CHW EHR LYM Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="single-accordion mb-20 wow fadeInUp" data-wow-delay=".2s">
                                    <div class="accordion-btn">
                                        <button class="btn-block text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                                            aria-controls="collapseTwo">
                                            <span>Parasite</span>
                                        </button>
                                    </div>

                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="accordion-content">
                                            <div class="container mt-5">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>A</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">ANA CHW EHR LYM Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="single-accordion mb-20 wow fadeInUp" data-wow-delay=".2s">
                                    <div class="accordion-btn">
                                        <button class="btn-block text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
                                            aria-controls="collapseThree">
                                            <span>Allergy</span>
                                        </button>
                                    </div>

                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        data-parent="#accordionExample">
                                        <div class="accordion-content">
                                            <div class="container mt-5">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>A</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">ANA CHW EHR LYM Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="single-accordion mb-20 wow fadeInUp" data-wow-delay=".2s">
                                    <div class="accordion-btn">
                                        <button class="btn-block text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
                                            aria-controls="collapseFour">
                                            <span>Kidney</span>
                                        </button>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        data-parent="#accordionExample">
                                        <div class="accordion-content">
                                            <div class="container mt-5">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>A</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">ANA CHW EHR LYM Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="single-accordion mb-20 wow fadeInUp" data-wow-delay=".2s">
                                    <div class="accordion-btn">
                                        <button class="btn-block text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseFive" aria-expanded="true"
                                            aria-controls="collapseFive">
                                            <span>Kidey</span>
                                        </button>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                        data-parent="#accordionExample">
                                        <div class="accordion-content">
                                            <div class="container mt-5">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>A</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">ANA CHW EHR LYM Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                        <div class="p-3" style="text-align: left;">
                                                            <h3>C</h3>
                                                            <ul>
                                                                <li><a style="font-size: 15px;">CAVI Test Kits</a></li>
                                                                <li><a style="font-size: 15px;">CAVII Ag Test Kits</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ========================= accordion-section end ========================= -->

        <!--sapT-->
        <section class="container text-reset pb-30">
            <h3 style="color: #3763EB;">Small Animal Products</h3>
            <p class="fs-6 fw-bold" style="color: #3763EB;">
                OME Service<br>
                Partner Manufacturing<br>
                Uncut-sheets & Raw Maerials
            </p>
        </section>
        <!--end-->

        <!-- ========================= pricing-section start ========================= -->
    <section class="pricing-section pt-200 pb-200" style="background-color: #f7f7f7;">
      <div class="container">
        <div>
          <p style="font-size: 25px; color: black;">Click the Button to Get Professional Design Advice</p>
          <br>
          <div class="pt-20 pb-20">
            <button type="button" class="btn btn-primary">Contact us</button>
          </div>
        </div>
      </div>
    </section>
    <!-- ========================= pricing-section end ========================= -->

    <!--bottom-->
    <section class="container-fluid"
      style="background-color: black; color: white; padding: 20px 50px 20px 50px;">
      <div class="row" style="display: flex; flex-wrap: wrap; text-align: left;">
        <div class="col-12 col-sm-6 col-md-6 col-lg-3"
          style="padding: 15px; box-sizing: border-box;text-align: center;">
          <img style="width: 140px; height: 28px;" src="@/assets/img/logo/logow.svg">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
          <h4 style="color:white;">Our Company</h4>
          <ul>
            <li>About us</li>
            <li>How nature's world</li>
            <li>Become a partner</li>
          </ul><br>
          <h4 style="color:white;">Service</h4>
          <ul>
            <li>OEM Service</li>
            <li>About sheets & law Material</li>
            <li>After-sales Service</li>
          </ul><br>
          <h4 style="color:white;">Resources</h4>
          <ul>
            <li>Blog</li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
          <h4 style="color:white;">Our Product</h4>
          <ul>
            <li>Infectious Diseases</li>
            <li>Parasites</li>
            <li>Allergy</li>
            <li>Myocardium</li>
            <li>Metabolism</li>
            <li>Kidney</li>
            <li>Pancreatic</li>
            <li>Inflammation</li>
            <li>Cardiac</li>
            <li>Bacteria</li>
            <li>Pregnancy</li>
          </ul><br>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
          <h4 style="color:white;">Contact us</h4>
          <ul>
            <li>E-mail: info@tashikin.com</li>
            <li>Tel: +(726) 334-1765</li>
          </ul><br>
        </div>
      </div>
    </section>
    <!--bottom-->
    </div>
</template>

<script>
export default {

}
</script>

<style></style>