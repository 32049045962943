<template>
 
    <!-- ========================= header start ========================= -->
    <header class="header navbar-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand">
                <router-link to="/">
                  <img src="./assets/img/logo/logo.svg" alt="Logo">
                </router-link>
              </a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <a class="page-scroll active dd-menu" href="javascript:void(0)">How it Works</a>
                    <ul class="sub-menu">
                      <li class="nav-item active"><b><a>How-Tarinn-Works</a></b></li>
                      <li class="nav-item active"><router-link to="/how-it-works/how-tarinn-works/how-ordering-works">How-ordering-works</router-link></li>
                      <li class="nav-item active"><router-link to="/how-it-works/how-tarinn-works/oem-service">OEM Service</router-link></li>
                      <li class="nav-item active"><b><a>Recourse & Support</a></b></li>
                      <li class="nav-item active"><router-link to="/how-it-works/resource-support/directory-of-tests">Directory-Of-Tests</router-link></li>
                      <li class="nav-item active"><b><a>After-sales Service</a></b></li>
                      <li class="nav-item active"><router-link to="/how-it-works/after-sales-service/after-sales-service">After-sales Service</router-link></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="about.html">About</a>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll dd-menu" href="javascript:void(0)">Service</a>

                    <ul class="sub-menu">
                      <li class="nav-item"><a href="service-1.html">Service Style 1</a></li>
                      <li class="nav-item"><a href="service-2.html">Service Style 2</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll dd-menu" href="javascript:void(0)">Pages</a>

                    <ul class="sub-menu">
                      <li class="nav-item"><a href="about.html">About Us</a></li>
                      <li class="nav-item"><a href="team.html">Team Members</a></li>
                      <li class="nav-item"><a href="portfolio.html">Portfolio</a></li>
                      <li class="nav-item"><a href="portfolio-single.html">Portfolio Single</a></li>
                      <li class="nav-item"><a href="counter.html">Counter</a></li>
                      <li class="nav-item"><a href="accordion.html">Accordion</a></li>
                      <li class="nav-item"><a href="alerts.html">Alerts</a></li>
                      <li class="nav-item"><a href="map.html">Map</a></li>
                      <li class="nav-item"><a href="404.html">404</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll dd-menu" href="javascript:void(0)">Blog</a>

                    <ul class="sub-menu">
                      <li class="nav-item"><a href="blog-1.html">Blog Style 1</a></li>
                      <li class="nav-item"><a href="blog-2.html">Blog Style 2</a></li>
                      <li class="nav-item"><a href="blog-single.html">Blog Single</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="page-scroll" href="contact.html">Contact</a>
                  </li>
                </ul>
                <form action="#" class="search-form">
                  <input type="text" placeholder="Search">
                  <button type="submit"><i class="lni lni-search-alt"></i></button>
                </form>
              </div> <!-- navbar collapse -->
            </nav> <!-- navbar -->
          </div>
        </div> <!-- row -->
      </div> <!-- container -->

    </header>
    <!-- ========================= header end ========================= -->
    <main><router-view></router-view></main>



</template>

<script>
// import { tns } from 'tiny-slider';

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
