<template>
  <div class="after-sales-service">
    <div>
            <br><br>
        </div>
        <!-- ========================= imgtop start ========================= -->
        <section class="container-fluid p-0">
            <div class="row g-0">
                <div class="col-md-12 col-lg-6">
                    <div class="row bg-light">
                        <img src="@/assets/img/how-it-works/oem-home-left.png" alt="" style="width: 100%;" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-6" style="background-color: #f7f7f7;">
                    <div style="text-align: left; padding: 20px 10px 10px 50px;">
                        <h1 style="color: #3763EB;">After-sales Service</h1><br>
                        <p class="fs-5 pr-5" style="color: black;">
                          We provide exceptional after-sales service, ensuring customer satisfaction and product support.
                        </p><br><br>
                        <button type="button" class="btn btn-primary">Contact us</button>
                        <p>All uploads are secure and confidential</p><br>
                        <img src="@/assets/img/home/iso.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <!-- ========================= end ========================= -->

        <!--content-->
        <section>
            <div class="container p-1 mt-2 mb-2" style="max-width: 1000px;">
                <a class="fs-2 p-2 fw-bold">After Sales without Worry</a>
                <p class="fs-5 p-2" style="color: black;">With a group of dedicated and competent engineers and technicians, RapidDiert can help tackle your concerns with proactive and professional support 24/7.</p>
                <p class="fs-5 p-2" style="color: black;">
                  ·1-on-lTechnical support<br><br>
                  ·Professional Design Support<br><br>
                  ·Elite medical R&D and technicians
                </p>
                <a class="fs-2 p-2 fw-bold">Contact us</a>
                <p class="fs-5 p-2 pb-5" style="color: black;">
                  If you have any additional questions about Rework and Refund Policy, please contact your Account Manager, tashikin@www.com or call us on+86-0571-87763175(CN)or+86-13282126880(CN).
                </p>
              </div>
        </section>
        <!--end-->

        <!-- ========================= pricing-section start ========================= -->
        <section class="pricing-section pt-200 pb-200 mt-3" style="background-color: #f7f7f7;">
            <div class="container">
                <div>
                    <p style="font-size: 25px; color: black;">Click the Button to Get Professional Design Advice</p>
                    <br>
                    <div class="pt-20 pb-20">
                        <button type="button" class="btn btn-primary">Contact us</button>
                    </div>
                </div>
            </div>
        </section>
        <!-- ========================= pricing-section end ========================= -->

        <!--bottom-->
        <section class="container-fluid"
            style="background-color: black; color: white; padding: 20px 50px 20px 50px;">
            <div class="row" style="display: flex; flex-wrap: wrap; text-align: left;">
                <div class="col-12 col-sm-6 col-md-6 col-lg-3"
                    style="padding: 15px; box-sizing: border-box;text-align: center;">
                    <img style="width: 140px; height: 28px;" src="@/assets/img/logo/logow.svg">
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
                    <h4 style="color:white;">Our Company</h4>
                    <ul>
                        <li>About us</li>
                        <li>How nature's world</li>
                        <li>Become a partner</li>
                    </ul><br>
                    <h4 style="color:white;">Service</h4>
                    <ul>
                        <li>OEM Service</li>
                        <li>About sheets & law Material</li>
                        <li>After-sales Service</li>
                    </ul><br>
                    <h4 style="color:white;">Resources</h4>
                    <ul>
                        <li>Blog</li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
                    <h4 style="color:white;">Our Product</h4>
                    <ul>
                        <li>Infectious Diseases</li>
                        <li>Parasites</li>
                        <li>Allergy</li>
                        <li>Myocardium</li>
                        <li>Metabolism</li>
                        <li>Kidney</li>
                        <li>Pancreatic</li>
                        <li>Inflammation</li>
                        <li>Cardiac</li>
                        <li>Bacteria</li>
                        <li>Pregnancy</li>
                    </ul><br>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding: 15px; box-sizing: border-box;">
                    <h4 style="color:white;">Contact us</h4>
                    <ul>
                        <li>E-mail: info@tashikin.com</li>
                        <li>Tel: +(726) 334-1765</li>
                    </ul><br>
                </div>
            </div>
        </section>
        <!--bottom-->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>