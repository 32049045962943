import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// eslint-disable-next-line
/* eslint-disable */

// 引入Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
// 引入Bootstrap JS（bundle版本）
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// css 模板文件夹样式
import './assets/css/animate.css'
import './assets/css/bootstrap-5.0.0-alpha.min.css'
import './assets/css/bootstrap-5.0.0-beta1.min.css'
import './assets/css/glightbox.min.css'
import './assets/css/LineIcons.2.0.css'
import './assets/css/main.css'
//import './assets/css/main.css.map.css'
//.map用于测试可注释掉
//import './assets/css/tiny-slider.css'
//tiny-slider已下载


import './assets/js/bootstrap.bundle-5.0.0-beta1.min.js'
import './assets/js/bootstrap.bundle-5.0.0.alpha-min.js'
import './assets/js/contact-form.js'
import './assets/js/tiny-slider.js'

createApp(App).use(router).mount('#app')